import { Layout, Space, FloatButton } from 'antd';
import AppHeader from '../app-header';
import AppFooter from '../app-footer';
import NotFoundPage from '../not-found-page';
import {
    MainPage,
    ProductPage,
    CategoriesPage,
    ContactsPage,
    PolicyPage,
    AboutCompanyPage
} from '../pages'
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './app.css';
import BackTopBtn from "../static/backtop.svg";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const { Header, Footer, Content } = Layout;

const IconBackTopBtn = () => (
<div>
<img src={BackTopBtn} alt='' width={"100%"} />
</div>
);


const App = () => (
    <Router>
        <ScrollToTop />
        <Space
            direction="vertical"
            style={{
                width: '100%',
            }}
            size={[0, 48]} >
            <Layout style={{
                backgroundColor: '#36322F'
            }}>
                <Header
                    style={{
                        backgroundColor: '#36322F',
                        height: 'auto',
                        padding: 0
                    }}>
                    <AppHeader />
                </Header>
                <Content>
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/catalogue/" exact element={<CategoriesPage />} />
                        <Route path="/product/:id" element={<ProductPage />} />
                        <Route path="/contacts" element={<ContactsPage />} />
                        <Route path="/personal_information" element={<PolicyPage />} />
                        <Route path="/aboutus" element={<AboutCompanyPage />} />
                        <Route path="/link" element={<MainPage />} />
                        <Route path="*" exact="false" element={<NotFoundPage />} />
                    </Routes>
                    <FloatButton.BackTop className='back-top-btn'
                         icon={<IconBackTopBtn/>}/> 
                </Content>
                <Footer style={{
                    backgroundColor: '#36322F',
                    height: '222px'}}>
                        <AppFooter />
                </Footer>
            </Layout>
        </Space>
    </Router >
);
export default App;