import { Row, Col, Image, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import AppMap from '../app-map/app-map';
import AppClients from '../app-clients/app-clients';
import AppReviews from '../app-reviews/app-reviews';
import AppTitle from '../app-title/app-title';
import Feedback3 from '../feedback/feedback3';

import './about-company-page.css';
import banner from './static/banner-about-company.webp';
import aboutCompanyImg from './static/about-company-img.webp';

const AboutCompanyPage = () => (
    <>
    <title>О компании General Trucks</title>
    <meta name="description" content="Компания занимается продажей тягачей, полуприцепов и легковых авто, а также профессиональным обслуживанием и ремонтом техники DAF" />
        <Breadcrumb className='content breadCrumb contact-breadCrumb'
            items={[
            {
                title: <Link to="/">Главная</Link>,
            },
            {
                title: 'О компании',
            },
            ]}
        />
        <Row className='about_company_banner'>
            <Image preview={false} src={banner} />
        </Row>
        <AppTitle text={'О компании'}/>
        <Row className='content about_company'>
            <p>Компания ООО "Дженерал Сервис" специализируется на предоставлении высококачественных услуг в сфере продажи техники для грузоперевозок, сервисного обслуживания грузовиков марки DAF, а также легкого коммерческого транспорта и легковых автомобилей.</p>
            <p>С нашим опытом работы более 11 лет, мы стали надежным партнером для множества клиентов. Оперативно реагируем на их потребности, предлагая широкий спектр услуг в наших 5 центрах, расположенных в городах Тольятти, Казань, Набережные Челны, Екатеринбург и Уфа.</p>
        </Row>
        <AppMap />
        <Row className='content about_company_offers'>
            <Row className='about_company_offers-colums'> 
                <Col>
                    <Image preview={false} src={aboutCompanyImg} />
                </Col>
                <Col>
                    <p>Что мы предлагаем:</p>
                    <ul>
                        <li>Продажа как новых, так и подержанных грузовиков марки DAF, Scania и Volvo</li>
                        <li>Поставка новых полуприцепов марок Schmitz и Krone</li>
                        <li>Предложение новых цельнометаллических фургонов марки Ford</li>
                        <li>Сервисное обслуживание грузовиков марки DAF</li>
                        <li>Оказание технической помощи и услуги по эвакуации</li>
                        <li>Продажа оригинальных и неоригинальных запасных частей для европейских грузовиков и прицепов</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <p>Наше техническое оборудование и профессиональные мастера на станциях технического обслуживания готовы решить любую задачу при обслуживании и ремонте вашего грузовика. Мы также гарантируем наличие широкого ассортимента запасных частей на нашем складе, чтобы ваш автомобиль не простаивал из-за ожидания деталей.</p>
                <p>Мы ценим каждого клиента и оказываем поддержку в любой ситуации. Наша команда готова помочь вам с выбором техники, сервисным обслуживанием и запасными частями. 
                <br />При выборе ООО "Дженерал Сервис" вы делаете правильный выбор для вашего бизнеса.</p>
            </Row>
        </Row>
        <AppTitle text={'Отзывы'} />
        <AppReviews />
        <AppTitle text={'Наши клиенты'} />
        <AppClients />
        <Row>
            <Feedback3 />
        </Row>
    </>
);

export default AboutCompanyPage;
