import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Typography, Button, ConfigProvider, Space } from 'antd';
import './not-found-page.css'

const { Text } = Typography;

const NotFoundPage = () => (
  <>
   <title>Страница не найдена | Ошибка 404</title>
    <meta name="description" content="Упс! Запрашиваемая страница не найдена. Проверьте адрес или вернитесь на главную. Мы всегда готовы помочь!" />
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#F67828',
            colorPrimaryHover: '#F67828',
            colorPrimaryActive: '#F67828',
          },
        },
      }}
    >
      <div className='not-found-page'>
        <Row>
          <Col span={14} className='not-found-page-content not-found-page_title'>
            <Text
              style={{
                fontSize: "128px",
                fontWeight: "400",
              }}>
              404 ошибка</Text></Col>
        </Row>
        <Row>
          <Col span={24} className='not-found-page-content not-found-page_texterror'>
            <Text
              style={{
                fontSize: "40px",
                fontWeight: "300",
              }}>
              Этой страницы не существует</Text></Col>
        </Row>
        <Row>
          <Col span={12} className='not-found-page-content'>
            <Button type='primary' className='go-to-main-button'>
              <Link to='/'>Перейти на главную страницу</Link>
            </Button>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  </>
);
export default NotFoundPage;